import React from 'react'

import SEO from '../common/seo'

import { Layout } from '../components/containers/layout'

import ProfileImage from '../components/presentationals/ProfileImage'
import Socials from '../components/presentationals/Socials'
import TitleWithCTA from '../components/presentationals/TitleWithCTA'
import ShareStickyNav from '../components/containers/ShareStickyNav'

const SecondPage = () => (
  <Layout>
    <SEO title="Content page" />

    <ShareStickyNav />

    <div className="container container--xs section-spacing">
      <div className="profile-photo">
        <ProfileImage alt="profile-photo" />
      </div>
      <div className="pretitle-section">
        <span className="pretitle-section__author">By JERONIMAS DARGIS</span>
        <span className="pretitle-section__socials">
          <Socials />
        </span>
      </div>
      <div className="rich-text">
        <h1>Lorem ipsum Lorem ipsum Lorem ipsum?</h1>
        <div className="content-subtitle">
          <div className="content-subtitle__info">
            {/* eslint-disable jsx-a11y/accessible-emoji */}
            March 3, 2019 •{' '}
            <span role="img" area-label="Coffe cup">
              ☕️
            </span>{' '}
            14 min read
          </div>
          <div className="content-subtitle__text">
            They’re a whole different Animal.
          </div>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed neque
          sapien, faucibus in sem at, placerat dictum ex. Sed lacinia tincidunt
          nisl, at sollicitudin massa pretium id. Morbi in pharetra purus.
          Maecenas vehicula sodales pretium. Nam sed tortor at neque feugiat
          bibendum. Proin quis luctus odio. Morbi eu quam quis mauris posuere
          finibus quis et eros. Suspendisse potenti. Pellentesque sodales metus
          magna, quis accumsan quam ornare non. Proin facilisis orci sed
          efficitur egestas. Quisque gravida dignissim odio, at sagittis quam
          hendrerit quis. Praesent sodales, odio ut pulvinar viverra, elit
          ligula varius orci, sit amet dapibus massa lacus eget urna. Sed cursus
          leo maximus, hendrerit lorem id, eleifend nibh. Donec quam tellus,
          luctus egestas libero ut, scelerisque elementum mi. Integer id nulla
          non tortor laoreet semper. Nam vehicula in felis sed suscipit.
        </p>
        <p>
          Etiam ut nunc consequat, lacinia nibh nec, hendrerit metus. Aliquam
          eget nulla quis erat dictum congue. Proin interdum nisi non justo
          mollis aliquam. Donec ac facilisis elit, ut congue mauris.
          Pellentesque imperdiet nunc tellus, ac maximus purus rutrum non. Nam
          tincidunt, libero et pharetra congue, orci tortor scelerisque lectus,
          et dapibus odio sem at leo. Donec pulvinar elit a nibh auctor, vitae
          gravida est luctus. Aenean efficitur nulla quis sapien bibendum, in
          malesuada quam tempus. Curabitur tempus congue lacinia. Maecenas
          rhoncus lacinia lectus, id accumsan diam convallis eu. Proin suscipit,
          massa at lobortis volutpat, ante sem dapibus lacus, a varius ex neque
          sit amet metus. Aenean quis magna at nisi gravida malesuada. Aliquam
          tristique nibh vel odio lacinia, at tincidunt turpis faucibus. Ut
          accumsan lectus id velit malesuada dictum. Cras convallis pretium
          ipsum a finibus. In hac habitasse platea dictumst.
        </p>
        <p>
          Morbi mi eros, euismod in cursus in, molestie tempor turpis. Praesent
          id leo in nibh cursus dignissim. Mauris dignissim tortor vel tortor
          iaculis bibendum. Cras convallis aliquet nibh sit amet ultricies.
          Suspendisse tincidunt justo quis libero pretium, pretium interdum
          felis facilisis. Proin id mi sagittis metus condimentum iaculis.
          Vestibulum dictum volutpat eros, in rhoncus felis facilisis eget. Sed
          quis sagittis elit. Sed lobortis maximus metus, bibendum tincidunt
          velit ultricies non. Vestibulum convallis tortor ut tortor rutrum, id
          interdum ipsum volutpat. Ut non neque laoreet mi elementum blandit in
          a odio.
        </p>
      </div>
    </div>
    <div className="container section-spacing section-spacing--y">
      <TitleWithCTA subtitle="Tags:" />
      <div className="content-tags">
        {['HTML', 'CSS', 'SASS', 'INSTAGRAM REMAKE'].map(item => (
          <span className="tag content-tags__tag">{item}</span>
        ))}
      </div>
    </div>
  </Layout>
)

export default SecondPage

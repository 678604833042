import React from 'react'

import { FacebookShareButton, FacebookMessengerShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'


const ShareStickyNav = () => {

  const url = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <div className="share-sticky-nav">
      <LinkedinShareButton url={url} className="share-sticky-nav__item">
        <i className="fab fa-linkedin share-sticky-nav__item__icon"></i>
        <span className="share-sticky-nav__item__tooltip">Share on Linkedin</span>
      </LinkedinShareButton>
      <FacebookMessengerShareButton url={url} className="share-sticky-nav__item">
        <i className="fab fa-facebook-messenger share-sticky-nav__item__icon"></i>
        <span className="share-sticky-nav__item__tooltip">Share on Messenger</span>
      </FacebookMessengerShareButton>
      <FacebookShareButton url={url} className="share-sticky-nav__item">
        <i className="fab fa-facebook-f share-sticky-nav__item__icon"></i>
        <span className="share-sticky-nav__item__tooltip">Share on Facebook</span>
      </FacebookShareButton>
      <TwitterShareButton url={url} className="share-sticky-nav__item">
        <i className="fab fa-twitter share-sticky-nav__item__icon"></i>
        <span className="share-sticky-nav__item__tooltip">Share on Twitter</span>
      </TwitterShareButton>
    </div>
  )
}

export default ShareStickyNav;